<template>
	<div id="alarmData">
		报警信息
	</div>
</template>

<script>
	export default {

	}
</script>

<style lang="scss">
</style>
